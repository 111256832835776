<template>
  <div class="customers">
    <!-- Breadcrumb and back button -->
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="hidden-sm-and-down text-right"
      >
        <v-btn
          class="go-back-button"
          @click="$router.go(-1)"
        >
          <v-icon left>
            {{ icons.mdiArrowLeft }}
          </v-icon>
          Go back
        </v-btn>
      </v-col>
    </v-row>

    <!-- Ticket Details Section -->
    <v-row>
      <v-col cols="12">
        <v-card class="ticket-details-card rounded-lg">
          <!-- Ticket Header -->
          <v-card-title class="py-4 px-6 ticket-header">
            <v-row align="center">
              <v-col
                cols="12"
                sm="8"
              >
                <div class="d-flex align-center flex-wrap">
                  <vue-skeleton-loader
                    v-show="loading"
                    :width="345"
                    :height="28"
                  />
                  <h3 class="ticket-title font-weight-bold">
                    <span class="ticket-number">{{ ticket.ticket_number }}</span>
                    <span class="mx-2">|</span>
                    {{ ticket.subject }}
                  </h3>
                  <v-chip
                    small
                    :color="getStatusColor(ticket.status)"
                    class="ml-2 status-chip"
                    pill
                  >
                    {{ ticket.status_label }}
                  </v-chip>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-end"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Actions
                      <v-icon right>
                        {{ icons.mdiMenuDown }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="can('ticket-edit')"
                      :disabled="isSendingPortalCredentialsMessage | loading"
                      @click="showEditTicketDialog = true"
                    >
                      <v-list-item-title>Edit Ticket</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="can('ticket-edit')"
                      :disabled="isConfirmCloseTicketDialogButtonLoading | loading"
                      @click="toggleTicketStatus"
                    >
                      <v-list-item-title>{{ ticket.status === 'open' ? 'Close Ticket' : 'Reopen Ticket' }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider></v-divider>

          <!-- Ticket Details Section -->
          <v-card-text class="pa-6">
            <v-row>
              <!-- Left Column - Ticket Information -->
              <v-col
                cols="12"
                md="6"
              >
                <h4 class="section-title">
                  <v-icon
                    left
                    color="primary"
                  >
                    {{ icons.mdiTicketOutline }}
                  </v-icon>
                  Ticket Information
                </h4>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiTagOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Issue Type
                    </v-list-item-subtitle>

                    <v-list-item-title class="detail-value">
                      <vue-skeleton-loader
                        v-show="loading"
                        :width="120"
                        :height="23"
                      />
                      {{ ticket.category_label }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiCalendarOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Created On
                    </v-list-item-subtitle>
                    <v-list-item-title class="detail-value">
                      <vue-skeleton-loader
                        v-show="loading"
                        :width="180"
                        :height="23"
                      />
                      {{ formatDate(ticket.created_at) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiClockOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Last Updated
                    </v-list-item-subtitle>
                    <v-list-item-title class="detail-value">
                      <vue-skeleton-loader
                        v-show="loading"
                        :width="180"
                        :height="23"
                      />
                      {{ formatDate(ticket.updated_at) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiAccountOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Assigned To
                    </v-list-item-subtitle>
                    <vue-skeleton-loader
                      v-show="loading"
                      :width="180"
                      :height="25"
                    />
                    <v-list-item-title class="detail-value">
                      <v-chip
                        small
                        :color="ticket.assigned_to ? 'success' : 'warning'"
                        pill
                      >
                        {{ ticket.assigned_to ? ticket.assigned_to.name : 'Unassigned' }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- Right Column - Customer Information -->
              <v-col
                cols="12"
                md="6"
              >
                <h4 class="section-title">
                  <v-icon
                    left
                    color="primary"
                  >
                    {{ icons.mdiAccountMultipleOutline }}
                  </v-icon>
                  Customer Information
                </h4>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiAccountOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Name
                    </v-list-item-subtitle>
                    <v-list-item-title class="detail-value">
                      <vue-skeleton-loader
                        v-show="loading"
                        :width="180"
                        :height="23"
                      />
                      {{ ticket.customer.user.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiPhone }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Phone
                    </v-list-item-subtitle>
                    <v-list-item-title class="detail-value">
                      <vue-skeleton-loader
                        v-show="loading"
                        :width="180"
                        :height="23"
                      />
                      {{ ticket.customer.user.phone_number }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiCardAccountDetailsOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Account Number
                    </v-list-item-subtitle>
                    <v-list-item-title class="detail-value">
                      <vue-skeleton-loader
                        v-show="loading"
                        :width="180"
                        :height="23"
                      />
                      {{ ticket.customer.account_number }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="px-0">
                  <v-list-item-avatar
                    tile
                    size="24"
                    class="mr-3"
                  >
                    <v-icon small>
                      {{ icons.mdiMapMarkerOutline }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="detail-label">
                      Station
                    </v-list-item-subtitle>
                    <v-list-item-title class="detail-value">
                      <vue-skeleton-loader
                        v-show="loading"
                        :width="180"
                        :height="23"
                      />
                      {{ ticket.customer.station.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <!-- Description Section -->
            <v-row class="mt-6">
              <v-col cols="12">
                <h4 class="section-title">
                  <v-icon
                    left
                    color="primary"
                  >
                    {{ icons.mdiTextBoxOutline }}
                  </v-icon>
                  Description
                </h4>
                <v-card
                  class="description-card"
                  outlined
                >
                  <v-card-text class="description-content">
                    <vue-skeleton-loader
                      v-show="loading"
                      :width="400"
                      :height="20"
                    />
                    {{ ticket.description }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!-- Ticket Attachments Section -->
            <v-row
              v-if="ticket.attachments && ticket.attachments.length > 0"
              class="mt-6"
            >
              <v-col cols="12">
                <h4 class="section-title">
                  <v-icon
                    left
                    color="primary"
                  >
                    {{ icons.mdiPaperclip }}
                  </v-icon>
                  Ticket Attachments
                </h4>
                <v-row>
                  <v-col
                    v-for="(attachment, index) in ticket.attachments"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <v-card
                      outlined
                      hover
                      class="attachment-card"
                    >
                      <div
                        class="attachment-preview"
                        :class="{'cursor-pointer': isImageOrVideo(attachment)}"
                        @click="isImageOrVideo(attachment) && openAttachmentDialog(attachment)"
                      >
                        <!-- Image preview -->
                        <img
                          v-if="isImageFile(attachment.file_name)"
                          :src="attachment.file_url"
                          class="attachment-img"
                          alt="Attachment"
                        />
                        <!-- Video preview -->
                        <video
                          v-else-if="isVideoFile(attachment.file_name)"
                          class="attachment-video"
                        >
                          <source
                            :src="attachment.file_url"
                            :type="getVideoMimeType(attachment.file_name)"
                          >
                          Your browser does not support the video tag.
                        </video>
                        <!-- Document preview (icon) -->
                        <div
                          v-else
                          class="document-icon"
                        >
                          <v-icon size="48">
                            {{ getFileIcon(attachment.file_name) }}
                          </v-icon>
                        </div>
                      </div>
                      <v-divider></v-divider>
                      <v-card-text class="pa-2 text-center attachment-text">
                        <div class="text-truncate filename">
                          {{ attachment.original_name }}
                        </div>
                        <v-btn
                          text
                          small
                          color="primary"
                          :href="attachment.file_url"
                          target="_blank"
                          class="mt-1"
                        >
                          <v-icon
                            small
                            left
                          >
                            {{ icons.mdiDownload }}
                          </v-icon>
                          Download
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Attachment Dialog -->
              <v-dialog
                v-model="attachmentDialog"
                max-width="90vw"
                @click:outside="closeDialog"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ selectedAttachment ? selectedAttachment.original_name : '' }}
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="closeDialog"
                    >
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <div class="dialog-content-wrapper">
                      <!-- Image content -->
                      <img
                        v-if="selectedAttachment && isImageFile(selectedAttachment.file_name)"
                        :src="selectedAttachment.file_url"
                        class="dialog-img"
                        alt="Attachment Preview"
                      />
                      <!-- Video content -->
                      <video
                        v-if="selectedAttachment && isVideoFile(selectedAttachment.file_name)"
                        controls
                        class="dialog-video"
                      >
                        <source
                          :src="selectedAttachment.file_url"
                          :type="getVideoMimeType(selectedAttachment.file_name)"
                        >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      :href="selectedAttachment ? selectedAttachment.file_url : ''"
                      :disabled="loading"
                      target="_blank"
                    >
                      <v-icon left>
                        {{ icons.mdiDownload }}
                      </v-icon>
                      Download
                    </v-btn>
                    <v-btn
                      color="grey darken-1"
                      :disabled="loading"
                      text
                      @click="closeDialog"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

            <!-- Comments Section -->
            <v-row
              v-if="ticket.comments && ticket.comments.length > 0"
              class="mt-6"
            >
              <v-col cols="12">
                <h4 class="section-title">
                  <v-icon
                    left
                    color="primary"
                  >
                    {{ icons.mdiMessageTextOutline }}
                  </v-icon>
                  Comments <span class="caption ml-2">({{ ticket.comments.length }})</span>
                </h4>
                <v-timeline>
                  <v-timeline-item
                    v-for="(comment, index) in ticket.comments"
                    :key="index"
                    :left="comment.is_from_customer"
                    :right="!comment.is_from_customer"
                    :color="comment.is_from_customer ? 'error' : 'primary'"
                    small
                  >
                    <template v-slot:icon>
                      <v-avatar size="36">
                        <v-icon>{{ comment.is_from_customer ? icons.mdiEyeOffOutline : icons.mdiMessageTextOutline }}</v-icon>
                      </v-avatar>
                    </template>
                    <template v-slot:opposite>
                      <div class="caption">
                        {{ formatDate(comment.created_at) }}
                      </div>
                    </template>
                    <v-card
                      outlined
                      class="comment-card my-2"
                    >
                      <v-card-title class="py-2 px-4 comment-header">
                        <div class="d-flex justify-space-between align-center w-100">
                          <div class="d-flex align-center">
                            <v-avatar
                              size="32"
                              color="primary"
                              class="mr-2"
                            >
                              <span class="white--text">{{ getInitials(comment.user ? comment.user.name : 'System') }}</span>
                            </v-avatar>
                            <span class="font-weight-medium">{{ comment.user ? comment.user.name : 'System' }}</span>
                          </div>
                          <v-chip
                            x-small
                            :color="comment.is_private ? 'info' : 'success'"
                            pill
                            class="ml-2"
                          >
                            {{ comment.is_private ? 'Private' : 'Public' }}
                          </v-chip>
                        </div>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="pa-4">
                        <div class="comment-text text-center">
                          {{ comment.comment }}
                        </div>

                        <!-- Comment Attachments -->
                        <v-row
                          v-if="comment.attachments && comment.attachments.length > 0"
                          class="mt-3"
                        >
                          <v-col
                            v-for="(attachment, attIndex) in comment.attachments"
                            :key="`comment-${index}-attachment-${attIndex}`"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                          >
                            <v-card
                              outlined
                              hover
                              class="attachment-card"
                            >
                              <div class="attachment-preview">
                                <!-- Image preview -->
                                <img
                                  v-if="isImageFile(attachment.file_name)"
                                  :src="attachment.file_url"
                                  class="attachment-img"
                                  alt="Attachment"
                                />
                                <!-- Video preview -->
                                <video
                                  v-else-if="isVideoFile(attachment.file_name)"
                                  controls
                                  class="attachment-video"
                                >
                                  <source
                                    :src="attachment.file_url"
                                    :type="getVideoMimeType(attachment.file_name)"
                                  >
                                  Your browser does not support the video tag.
                                </video>
                                <!-- Document preview (icon) -->
                                <div
                                  v-else
                                  class="document-icon"
                                >
                                  <v-icon size="48">
                                    {{ getFileIcon(attachment.file_name) }}
                                  </v-icon>
                                </div>
                              </div>
                              <v-divider></v-divider>
                              <v-card-text class="pa-2 text-center attachment-text">
                                <div class="text-truncate filename">
                                  {{ attachment.original_name }}
                                </div>
                                <v-btn
                                  text
                                  small
                                  color="primary"
                                  :href="attachment.file_url"
                                  target="_blank"
                                  class="mt-1"
                                >
                                  <v-icon
                                    small
                                    left
                                  >
                                    {{ icons.mdiDownload }}
                                  </v-icon>
                                  Download
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>

            <!-- Action Buttons -->
            <v-row class="mt-6">
              <v-col
                cols="12"
                class="d-flex justify-end"
              >
                <v-btn
                  color="primary"
                  class="mr-3"
                  elevation="2"
                  @click="showAddResponseDialog = true"
                >
                  <v-icon left>
                    {{ icons.mdiMessageReplyText }}
                  </v-icon>
                  Add Response
                </v-btn>
                <v-btn
                  :color="ticket.status === 'open' ? 'error' : 'success'"
                  dark
                  elevation="2"
                  :loading="isUpdatingStatus"
                  @click="toggleTicketStatus"
                >
                  <v-icon left>
                    {{ ticket.status === 'open' ? icons.mdiCloseCircleOutline : icons.mdiCheckCircleOutline }}
                  </v-icon>
                  {{ ticket.status === 'open' ? 'Close Ticket' : 'Reopen Ticket' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Add Response Dialog -->
    <v-dialog
      v-model="showAddResponseDialog"
      max-width="700px"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary darken-1 white--text">
          <v-icon
            left
            color="white"
          >
            {{ icons.mdiMessageReplyText }}
          </v-icon>
          Add Response
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeAddResponseDialog"
          >
            <v-icon color="white">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form
            ref="responseForm"
            v-model="isResponseFormValid"
            lazy-validation
          >
            <v-textarea
              v-model="newResponse.comment"
              label="Comment"
              :rules="[v => !!v || 'Comment is required']"
              rows="5"
              outlined
              auto-grow
              counter
              required
              placeholder="Type your response here..."
            ></v-textarea>

            <v-switch
              v-model="newResponse.is_private"
              label="Private Comment (Only visible to staff)"
              color="primary"
              class="mt-4"
              hint="Private comments cannot be seen by customers"
              persistent-hint
            ></v-switch>

            <!-- File upload section -->
            <v-file-input
              v-model="newResponse.files"
              label="Attachments"
              placeholder="Select files"
              multiple
              outlined
              prepend-icon="mdi-paperclip"
              :accept="acceptedFileTypes"
              show-size
              counter
              hint="Allowed file types: Images, Videos, Documents"
              persistent-hint
              class="mt-4"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            :disabled="isSubmittingResponse"
            @click="closeAddResponseDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :loading="isSubmittingResponse"
            :disabled="!isResponseFormValid || isSubmittingResponse"
            elevation="2"
            @click="submitResponse"
          >
            <v-icon left>
              {{ icons.mdiSend }}
            </v-icon>
            Submit Response
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Ticket Dialog -->
    <add-ticket-dialog
      :show-dialog="showEditTicketDialog"
      :ticket="ticket"
      @close="showEditTicketDialog=false;"
      @ticketAdded="getTicket"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {
  mdiPlus, mdiArrowLeft, mdiWifi, mdiWifiOff, mdiMenuDown, mdiInformationOutline,
  mdiMessageReplyText, mdiCloseCircleOutline, mdiCheckCircleOutline, mdiClose,
  mdiDownload, mdiFileDocumentOutline, mdiFileImageOutline, mdiFileVideoOutline,
  mdiFilePdfOutline, mdiFileExcelOutline, mdiFileWordOutline, mdiFilePowerpoint,
  mdiPaperclip, mdiPencil, mdiSend,
} from '@mdi/js'
import VueSkeletonLoader from 'skeleton-loader-vue'
import Breadcrumb from '@/components/partials/BreadCrumb.vue'
import AddTicketDialog from '@/components/dialogs/AddTicketDialog.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    Breadcrumb,
    AddTicketDialog,
    VueSkeletonLoader,
  },
  mixins: [hasPermission],
  data() {
    return {
      ticket: {
        assigned_to: {
          customer: null,
        },
        created_by: {
          customer: null,
        },
        customer: {
          station: {},
          user: {
            customer: {},
          },
        },
        comments: [],
        attachments: [],
      },
      loading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiWifi,
        mdiWifiOff,
        mdiMenuDown,
        mdiInformationOutline,
        mdiMessageReplyText,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiClose,
        mdiDownload,
        mdiFileDocumentOutline,
        mdiFileImageOutline,
        mdiFileVideoOutline,
        mdiFilePdfOutline,
        mdiFileExcelOutline,
        mdiFileWordOutline,
        mdiFilePowerpoint,
        mdiPaperclip,
        mdiPencil,
        mdiSend,
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Tickets',
          disabled: false,
          to: { name: 'tickets' },
        },
        {
          text: this.$route.params.id,
          disabled: true,
          to: {
            name: 'tickets',
          },
        },
      ],
      attachmentDialog: false,
      selectedAttachment: null,
      showEditTicketDialog: false,
      showAddResponseDialog: false,
      isSubmittingResponse: false,
      isUpdatingStatus: false,
      isResponseFormValid: false,
      isSendingPortalCredentialsMessage: false,
      isConfirmCloseTicketDialogButtonLoading: false,

      // New response data
      newResponse: {
        comment: '',
        is_private: false,
        files: [],
      },

      // File types
      acceptedFileTypes: '.jpg,.jpeg,.png,.gif,.mp4,.avi,.mov,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt',
    }
  },
  mounted() {
    this.getTicket()
  },
  methods: {
    async getTicket() {
      this.loading = true
      try {
        const response = await axios.get(`tickets/${this.$route.params.id}`)
        this.ticket = response.data
      } catch (error) {
        this.errorMessage = error.response.data.message
      } finally {
        this.loading = false
      }
    },
    getStatusColor(status) {
      const statusMap = {
        open: 'success',
        closed: 'error',
        pending: 'warning',
        in_progress: 'info',
      }

      return statusMap[status] || 'grey'
    },
    getPriorityColor(priority) {
      const priorityMap = {
        1: 'success', // Low
        2: 'warning', // Medium
        3: 'error', // High
      }

      return priorityMap[priority] || 'grey'
    },
    formatDate(dateString) {
      if (!dateString) return ''
      const date = new Date(dateString)

      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date)
    },

    // Close dialog and reset form
    closeAddResponseDialog() {
      this.showAddResponseDialog = false
      this.$nextTick(() => {
        this.$refs.responseForm.reset()
        this.newResponse = {
          comment: '',
          is_private: false,
          files: [],
        }
      })
    },

    // Submit response
    async submitResponse() {
      if (!this.$refs.responseForm.validate()) return

      this.isSubmittingResponse = true

      try {
        // Create FormData for file uploads
        const formData = new FormData()
        formData.append('ticket_id', this.ticket.id)
        formData.append('comment', this.newResponse.comment)
        formData.append('is_private', this.newResponse.is_private ? 1 : 0)

        // Add files if any
        if (this.newResponse.files && this.newResponse.files.length > 0) {
          this.newResponse.files.forEach((file, index) => {
            formData.append(`attachments[${index}][file]`, file)
          })
        }

        // Submit the response
        const response = await axios.post('ticket-comments', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        // Refresh ticket data
        await this.getTicket()
        this.$toast.success('Response added successfully')

        // Close dialog
        this.closeAddResponseDialog()
      } catch (error) {
        this.$toast.error(error.response?.data?.message || 'Failed to add response')
      } finally {
        this.isSubmittingResponse = false
      }
    },

    // Toggle ticket status (open/closed)
    async toggleTicketStatus() {
      this.isUpdatingStatus = true

      try {
        const newStatus = this.ticket.status === 'open' ? 'closed' : 'open'

        await axios.patch(`tickets/${this.ticket.id}/toggle-status`, {
          status: newStatus,
        })

        // Refresh ticket data
        await this.getTicket()

        this.$toast.success(`Ticket ${newStatus === 'open' ? 'reopened' : 'closed'} successfully`)
      } catch (error) {
        this.$toast.error(error.response?.data?.message || 'Failed to update ticket status')
      } finally {
        this.isUpdatingStatus = false
      }
    },

    // Remove file from selection
    removeFile(index) {
      this.newResponse.files.splice(index, 1)
    },

    // Check if file is an image
    isImageFile(filename) {
      const ext = this.getFileExtension(filename).toLowerCase()

      return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(ext)
    },

    // Check if file is a video
    isVideoFile(filename) {
      const ext = this.getFileExtension(filename).toLowerCase()

      return ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm'].includes(ext)
    },

    // Get file extension
    getFileExtension(filename) {
      return filename.split('.').pop()
    },

    // Get video MIME type
    getVideoMimeType(filename) {
      const ext = this.getFileExtension(filename).toLowerCase()
      const mimeTypes = {
        mp4: 'video/mp4',
        avi: 'video/x-msvideo',
        mov: 'video/quicktime',
        wmv: 'video/x-ms-wmv',
        flv: 'video/x-flv',
        webm: 'video/webm',
      }

      return mimeTypes[ext] || 'video/mp4'
    },

    // Get file icon based on file type
    getFileIcon(filename) {
      const ext = this.getFileExtension(filename).toLowerCase()

      // Document types
      if (['pdf'].includes(ext)) return this.icons.mdiFilePdfOutline
      if (['doc', 'docx'].includes(ext)) return this.icons.mdiFileWordOutline
      if (['xls', 'xlsx', 'csv'].includes(ext)) return this.icons.mdiFileExcelOutline
      if (['ppt', 'pptx'].includes(ext)) return this.icons.mdiFilePowerpoint

      // Media types
      if (this.isImageFile(filename)) return this.icons.mdiFileImageOutline
      if (this.isVideoFile(filename)) return this.icons.mdiFileVideoOutline

      // Default document icon
      return this.icons.mdiFileDocumentOutline
    },

    // Get file icon based on MIME type (for previews)
    getFileIconByType(mimeType) {
      if (mimeType.startsWith('image/')) return this.icons.mdiFileImageOutline
      if (mimeType.startsWith('video/')) return this.icons.mdiFileVideoOutline
      if (mimeType === 'application/pdf') return this.icons.mdiFilePdfOutline
      if (mimeType.includes('word') || mimeType === 'application/msword') return this.icons.mdiFileWordOutline
      if (mimeType.includes('excel') || mimeType.includes('spreadsheet')) return this.icons.mdiFileExcelOutline
      if (mimeType.includes('powerpoint') || mimeType.includes('presentation')) return this.icons.mdiFilePowerpoint

      return this.icons.mdiFileDocumentOutline
    },
    closeTicket() {
      this.isConfirmCloseTicketDialogButtonLoading = true
      this.toggleTicketStatus()
      this.isConfirmCloseTicketDialogButtonLoading = false
    },
    getInitials(name) {
      if (!name) return 'SY'

      return name.split(' ').map(n => n[0]).join('').toUpperCase()
        .substring(0, 2)
    },
    isImageOrVideo(attachment) {
      return this.isImageFile(attachment.file_name) || this.isVideoFile(attachment.file_name)
    },

    openAttachmentDialog(attachment) {
      this.selectedAttachment = attachment
      this.attachmentDialog = true
    },

    closeDialog() {
      this.attachmentDialog = false
      this.selectedAttachment = null
    },
  },
}
</script>

<style scoped>
/* Base styles with theme compatibility */
.ticket-details-card {
  transition: box-shadow 0.3s ease;
}

.ticket-header {
  border-bottom: 1px solid var(--v-primary-lighten4);
}

.ticket-title {
  line-height: 1.2;
  word-break: break-word;
}

.ticket-number {
  color: var(--v-primary-base);
}

/* Section styles */
.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--v-primary-lighten4);
}

/* Detail styles */
.detail-label {
  color: var(--v-grey-darken1);
  opacity: 0.7;
}

/* Description card */
.description-card {
  transition: box-shadow 0.3s ease;
}

.description-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.description-content {
  white-space: pre-line;
  line-height: 1.6;
  padding: 16px !important;
}

/* Comment styles */
.comment-card {
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.comment-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.comment-header {
  background-color: rgba(var(--v-theme-on-surface), 0.04);
}

.comment-text {
  white-space: pre-line;
  line-height: 1.6;
}

/* Attachment styles */
.attachment-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.attachment-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.attachment-preview {
  height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--v-theme-on-surface), 0.04);
}

.attachment-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.attachment-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.document-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.attachment-text {
  background-color: rgba(var(--v-theme-on-surface), 0.02);
}

/* Timeline customization */
:deep(.v-timeline-item__body) {
  max-width: calc(100% - 96px);
}

/* Status chip */
.status-chip {
  height: 24px !important;
}

.attachment-preview {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f5f5f5;
}

.cursor-pointer {
  cursor: pointer;
}

.attachment-img, .attachment-video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.document-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.attachment-text {
  min-height: 80px;
}

.filename {
  margin-bottom: 5px;
}

.dialog-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 80vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.dialog-img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.dialog-video {
  max-width: 100%;
  max-height: 70vh;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .section-title {
  }

  :deep(.v-timeline-item__body) {
    max-width: 100%;
  }
}
</style>
