<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Ticket</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdateTicket"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="form.category"
                    label="Issue Type"
                    :items="categories"
                    :loading="categories.length === 0"
                    outlined
                    :error="!!errors.category"
                    :error-messages="errors.category"
                    item-value="id"
                    item-text="display_name"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.subject"
                    label="Subject"
                    placeholder="Enter subject"
                    outlined
                    :rules="[v => !!v || 'Subject is required', v => v.length >= 5 || 'Minimum 5 characters']"
                    :error="!!errors.subject"
                    :error-messages="errors.subject"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="form.description"
                    label="Description"
                    placeholder="Provide detailed description"
                    outlined
                    :rules="[v => !!v || 'Description is required', v => v.length >= 10 || 'Minimum 10 characters']"
                    :error="!!errors.description"
                    :error-messages="errors.description"
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="form.priority"
                    label="Priority"
                    :items="priorities"
                    :loading="priorities.length === 0"
                    outlined
                    :error="!!errors.priority"
                    :error-messages="errors.priority"
                    item-value="id"
                    item-text="display_name"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="form.status"
                    label="Status"
                    :items="statuses"
                    :loading="statuses.length === 0"
                    outlined
                    :error="!!errors.status"
                    :error-messages="errors.status"
                    item-value="id"
                    item-text="display_name"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12 mb-6">
                  <CustomerAutocomplete
                    :initial-customer-id="userId"
                    :show-dialog="showDialog"
                    @update="updateUserId"
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.assigned_to"
                    label="Assign To"
                    :items="systemUsers"
                    item-text="name"
                    item-value="id"
                    outlined
                    :error="!!errors.assigned_to"
                    :error-messages="errors.assigned_to"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="form.attachments"
                    label="Attachments (Optional)"
                    multiple
                    accept=".jpg,.jpeg,.png,.gif,.mp4,.avi,.mov,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
                    outlined
                    chips
                    clearable
                    hint="Allowed file types: Images, Videos, Documents"
                    persistent-hint
                    :error="hasAttachmentErrors"
                  ></v-file-input>
                  <div
                    v-if="attachmentErrorMessages.length"
                    class="error--text text-caption"
                  >
                    <div
                      v-for="(message, index) in attachmentErrorMessages"
                      :key="index"
                    >
                      {{ message }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import CustomerAutocomplete from '@/components/partials/CustomerSearchAutocomplete.vue'

export default {
  components: { CustomerAutocomplete },
  props: {
    showDialog: Boolean,
    ticket: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      subject: '',
      category: '',
      description: '',
      priority: '',
      status: 'open',
      customer_id: '',
      assigned_to: '',
      attachments: [],
    }),
    errors: {},
    action: 'Submit',
    categories: [],
    priorities: [],
    statuses: [],
    systemUsers: [],
  }),
  computed: {
    userId() {
      return Object.keys(this.ticket).length > 0 ? this.ticket.customer.user_id : ''
    },
    hasAttachmentErrors() {
    // Check if there are any errors related to attachments
      return Object.keys(this.errors).some(key => key.startsWith('attachments'))
    },
    attachmentErrorMessages() {
    // Collect all error messages related to attachments
      const messages = []
      Object.keys(this.errors).forEach(key => {
        if (key.startsWith('attachments')) {
          if (Array.isArray(this.errors[key])) {
            messages.push(...this.errors[key])
          } else if (this.errors[key]) {
            messages.push(this.errors[key])
          }
        }
      })

      return messages
    },
  },
  watch: {
    ticket() {
      if (Object.keys(this.ticket).length > 0) {
        this.initializeUpdateTicket()
      } else {
        this.action = 'Save'
      }
    },
    showDialog(val) {
      if (val && Object.keys(this.ticket).length > 0) {
        this.initializeUpdateTicket()
      }
    },
  },
  mounted() {
    if (Object.keys(this.ticket).length > 0) {
      this.initializeUpdateTicket()
    }
    this.getCategories()
    this.getPriorities()
    this.getStatuses()
    this.getSystemUsers()
  },
  methods: {
    updateUserId(newUserId) {
      this.form.customer_id = newUserId
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form = new Form({
        subject: '',
        category: '',
        description: '',
        priority: '',
        status: 'open',
        customer_id: '',
        assigned_to: '',
        attachments: [],
      })
      this.errors = {}
    },
    saveOrUpdateTicket() {
      if (Object.keys(this.ticket).length > 0) {
        this.updateTicket()
      } else {
        this.addTicket()
      }
    },
    addTicket() {
      // Create FormData to properly handle file uploads
      const formData = new FormData()

      // Add all regular form fields
      Object.keys(this.form).forEach(key => {
        if (key !== 'attachments' && key !== 'errors' && key !== '_method') {
          formData.append(key, this.form[key])
        }
      })

      // Add attachments properly
      if (this.form.attachments && this.form.attachments.length) {
        this.form.attachments.forEach((file, index) => {
          formData.append(`attachments[${index}][file]`, file)
        })
      }

      // Set form as busy (for loading indicator)
      this.form.busy = true

      // Use axios directly since vform might not handle FormData properly
      axios.post('tickets', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.form.busy = false
          this.errors = {} // Clear errors
          this.$toast.success('Ticket created successfully')
          this.closeDialog()
          this.$emit('ticketAdded')
        })
        .catch(error => {
          this.form.busy = false

          // Handle validation errors
          if (error.response && error.response.status === 422) {
            this.errors = error.response.data.errors || {}
          } else {
            this.$toast.error(error.response?.data?.message || 'An error occurred')
          }
        })
    },
    updateTicket() {
      const formData = new FormData()

      // Add all regular form fields
      Object.keys(this.form).forEach(key => {
        if (key !== 'attachments' && key !== 'errors' && key !== '_method') {
          formData.append(key, this.form[key])
        }
      })

      // Add method for Laravel to recognize this as PUT request
      formData.append('_method', 'PUT')

      // Add attachments properly
      if (this.form.attachments && this.form.attachments.length) {
        this.form.attachments.forEach((file, index) => {
          formData.append(`attachments[${index}][file]`, file)
        })
      }

      // Set form as busy (for loading indicator)
      this.form.busy = true

      // Use axios directly
      axios.post(`tickets/${this.ticket.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.form.busy = false
          this.errors = {} // Clear errors
          this.$toast.success('Ticket updated successfully')
          this.closeDialog()
          this.$emit('ticketAdded')
        })
        .catch(error => {
          this.form.busy = false

          // Handle validation errors
          if (error.response && error.response.status === 422) {
            this.errors = error.response.data.errors || {}
          } else {
            this.$toast.error(error.response?.data?.message || 'An error occurred')
          }
        })
    },
    initializeUpdateTicket() {
      this.action = 'Update'
      this.form.update(this.ticket)
      this.form.customer_id = this.ticket.customer.id
      this.form.assigned_to = this.ticket.assigned_to ? this.ticket.assigned_to.id : ''
      this.form.attachments = []
      this.errors = {}
    },
    getCategories() {
      axios.get('ticket-categories')
        .then(response => { this.categories = response.data })
        .catch(error => { console.error(error) })
    },
    getPriorities() {
      axios.get('priorities')
        .then(response => { this.priorities = response.data })
        .catch(error => { console.error(error) })
    },
    getStatuses() {
      axios.get('ticket-statuses')
        .then(response => { this.statuses = response.data })
        .catch(error => { console.error(error) })
    },
    getSystemUsers() {
      axios.get('system-users?perPage=all')
        .then(response => { this.systemUsers = response.data })
        .catch(error => { console.error(error) })
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
